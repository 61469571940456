<template>
    <div>
        <div
            :style="{ top: topPosition }"
            class="d-flex flex-column justify-content-end align-items-end w-100 mb-2"
            style="position: sticky; z-index: 20; overflow: visible; height: 0"
        >
            <b-button
                class="color-primary mb-2 position-absolute"
                rounded
                style="width: 50px; height: 50px"
                type="is-dark"
                @click="collapseOpen = !collapseOpen"
            >
                <b-icon icon="bars" />
            </b-button>
            <div class="position-relative w-100 d-flex justify-content-end" style="height: 0">
                <div ref="collapse" :class="`collapse position-absolute bg-white ${collapseOpen ? 'show' : ''}`">
                    <div class="p-3 rounded bg-white border" style="height: calc(100vh - 300px); overflow-y: scroll">
                        <project v-if="campaign" :campaign="campaign" :readonly="!isAdmin" class="mb-3" />
                        <billing v-if="isAdmin && campaign" :campaign="campaign" class="mb-3" />
                        <consultants v-if="campaign" :readonly="!isAdmin" class="mb-3" />
                        <on-page-setting v-if="campaign" :readonly="!isAdmin" class="mb-3" />
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <a class="button is-light is-small text-decoration-none" @click="$router.push(computedBacklink)">
                <i class="fas fa-backward mr-1" />
                Retour aux accompagnements
            </a>
        </div>
        <div v-if="error">
            <div class="alert alert-danger" role="alert">
                {{ error }}
            </div>
        </div>
        <div v-else-if="campaign">
            <properties
                :campaign="campaign"
                :readonly="!isAdmin"
                class="mb-3"
                @openModalEmailLaunch="openModal('launching')"
            />
            <div class="campaign_body">
                <div class="campaign_main">
                    <urls-list v-if="campaign" :campaign="campaign" :readonly="!isAdmin" class="mb-3" />
                    <div class="row mb-3">
                        <div class="col-md-3 h-100">
                            <customer v-if="isAdmin" :campaign="campaign" :readonly="!isAdmin" />
                            <campaign-mail
                                v-if="
                                    isAdmin &&
                                    campaign &&
                                    (campaign.mails.launching || campaign.mails.monthlyReportMail) &&
                                    campaign.consultants.offSite
                                "
                            />
                        </div>
                        <div class="col-md-9">
                            <briefs v-if="isAdmin" class="h-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <b-tabs v-model="activeTab">
                    <b-tab-item v-if="isAdmin" label="Budget">
                        <budgets v-if="campaign" :active-tab="activeTab" :campaign="campaign" />
                    </b-tab-item>
                    <b-tab-item>
                        <template #header>
                            Offsite
                            <span
                                v-if="contents && contents.data.available && contents.data.available.length > 0"
                                class="badge badge-warning"
                            >
                                <i class="fas fa-exclamation" />
                            </span>
                        </template>
                        <b-loading v-model="contents.loading" :is-full-page="false" />
                        <contents-unattached
                            v-if="isAdmin && contents.data && contents.data.available"
                            :contents="contents.data.available"
                            :readonly="!isAdmin"
                            @submit="attachContent($event)"
                        />
                        <contents
                            :active-tab="activeTab"
                            :contents="contents.data"
                            :readonly="!isAdmin"
                            @detach="detachContent($event)"
                        />
                    </b-tab-item>
                    <b-tab-item label="Onsite">
                        <followup v-if="campaign" :active-tab="activeTab" :readonly="!isAdmin" />
                    </b-tab-item>
                    <b-tab-item v-if="isAdmin" :visible="false" label="APrécommandes">
                        <campaign-pre-orders-tab ref="preOrdersTab" @goToFollowPreOrdersTab="activeTab = 4" />
                    </b-tab-item>
                    <b-tab-item v-if="isAdmin" label="Précommandes">
                        <CampaignFollowPreOrdersTab ref="followPreOrdersTab" :active-tab="activeTab" />
                    </b-tab-item>
                    <b-tab-item v-if="isAdmin" label="Favoris">
                        <div v-if="activeTab === 5" class="flex">
                            <favorites
                                v-if="campaign && campaign.project"
                                :campaign="campaign"
                                :is-accompagnement="true"
                            />
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
        <div v-else>
            <nl-loader show="show" />
        </div>
        <nl-modal :open-modal="modalIsOpen" :tab-index="null" title="Gestion d'email" @hidden="modalIsOpen = false">
            <b-loading v-model="mailIsLoading" :is-full-page="false" />
            <template v-if="emailType === 'launching'">
                <CampaignLaunchingMail
                    :campaign="campaign"
                    @closeModal="closeModal"
                    @loading="bool => (this.mailIsLoading = bool)"
                />
            </template>
        </nl-modal>
        <portal-target multiple name="bootstrap" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import Briefs from '../../components/Campaigns/Briefs'
    import Budgets from '../../components/Campaigns/Budgets'
    import Billing from '../../components/Campaigns/Billing'
    import ContentsUnattached from '../../components/Campaigns/ContentsUnattached'
    import Favorites from '../Favorites'
    import CampaignMail from '../../components/Campaigns/CampaignMail'
    import CampaignFollowPreOrdersTab from '../../components/Campaigns/CampaignFollowPreOrdersTab'
    import CampaignPreOrdersTab from '../../components/Campaigns/CampaignPreOrdersTab'
    import NlModal from '../../components/UI/NlModal'
    import CampaignLaunchingMail from '../../components/Campaigns/CampaignLaunchingMail'

    import mixin from '../../components/Campaigns/mixins'

    export default {
        title: 'Accompagnement',
        name: 'CampaignManager',
        components: {
            Billing,
            Budgets,
            Briefs,
            ContentsUnattached,
            Favorites,
            CampaignMail,
            CampaignFollowPreOrdersTab,
            CampaignPreOrdersTab,
            NlModal,
            CampaignLaunchingMail
        },
        mixins: [mixin],
        props: {
            isAdmin: {
                type: Boolean,
                default: false
            },
            backLink: {
                type: String,
                default: '/campaigns'
            }
        },
        data() {
            return {
                availableConsultants: null,
                urls: [],
                activeTab: this.isAdmin ? 1 : 0,
                collapseOpen: false,

                //Email
                modalIsOpen: false,
                mailIsLoading: false,
                emailType: null
            }
        },
        computed: {
            ...mapState('campaigns', ['campaign', 'keywords']),
            ...mapState('contents', {
                contents: state => state.offsite
            }),
            computedBacklink() {
                if (!this.$store.state.auth.fromAdmin) {
                    return this.backLink
                } else {
                    return '/campaigns'
                }
            },
            topPosition() {
                // TODO revoir le sticky button via css only
                return this.$store.state.auth.connectedAs ? '182px' : '130px'
            }
        },
        methods: {
            ...mapActions('campaigns', ['getCampaignById', 'getKeywordsByCampaign']),
            ...mapActions('contents', ['getContentsByCampaign', 'attachCampaignContent']),
            attachContent: function (e) {
                this.attachCampaignContent({
                    ids: e,
                    accompagnement_id: this.$route.query.id
                })
            },
            closeModal() {
                this.modalIsOpen = false
                this.emailType = null
            },
            openModal(emailType) {
                if (this.campaign.project) {
                    this.emailType = emailType
                    this.modalIsOpen = true
                } else {
                    this.$refs.toaster.fire('error', 'Merci de sélectionner un projet')
                }
            }
        },
        mounted() {
            this.getCampaignById({ campaignId: this.$route.query.id }).catch(e => {
                if (e.response.status === 403) {
                    this.$router.push({ name: 'Campaigns' })
                }
            })
            this.getContentsByCampaign(this.$route.query.id)
        }
    }
</script>

<style scoped>
    .campaign_body {
        display: flex;
        flex-flow: row wrap;
        gap: 2rem;
        width: 100% !important;
    }

    .campaign_main {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
</style>
