import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'

import AccompagnementApi from '../../services/accompagnement/Endpoint'
import NlLoader from '../UI/NlLoader'
import Consultants from './Consultants'
import Contents from './Contents'
import Customer from './Customer'
import Followup from './Followup'
import OnPageSetting from './OnPageSetting'
import OnsiteActions from './OnsiteActions'
import Project from './Project'
import Properties from './Properties'
import UrlsList from './UrlsList'

export default {
    components: {
        Project,
        Contents,
        Consultants,
        Customer,
        Followup,
        Loading,
        NlLoader,
        OnPageSetting,
        OnsiteActions,
        Properties,
        UrlsList
    },
    data() {
        return {
            api: new AccompagnementApi(),
            properties: null,
            error: null
        }
    },
    computed: {
        ...mapState('campaigns', ['campaign', 'keywords'])
    },
    methods: {
        ...mapActions('campaigns', ['getCampaignById', 'getKeywordsByCampaign'])
    }
}
