<template>
    <div class="flex">
        <div
            v-if="
                campaign &&
                campaign.project &&
                campaign.project.user_id &&
                campaign.project.user_id !== user.id &&
                isAccompagnement
            "
            class="d-flex justify-content-center"
        >
            Merci de vous connecter au client pour afficher les favoris
            <LoginAs
                :user-id="this.campaign.customer.id"
                :manager-id="this.campaign.id"
                :redirect-to="'/admin/campaigns/details?id=' + this.campaign.id"
                class="ml-1 btn btn-link p-0"
            >
            </LoginAs>
        </div>
        <div v-else class="d-flex flex-column" style="gap: 1rem">
            <template v-if="isAccompagnement">
                <div class="d-flex align-items-center w-100" style="gap: 1rem">
                    <button class="btn btn-warning text-dark" @click="generateFavorites" :disabled="favoritesLoading">
                        <i v-if="favoritesLoading" class="fas fa-cog fa-spin" />
                        Lancer les favoris auto
                    </button>
                    <button
                        class="btn btn-warning text-dark"
                        @click="removeGeneratedFavorites"
                        :disabled="favoritesLoading"
                    >
                        <i v-if="favoritesLoading" class="fas fa-cog fa-spin" />
                        Supprimer les favoris auto
                    </button>
                </div>
                <hr />
            </template>
            <SearchLayout :get-data="searchByFavorites" :is-favorite="true">
                <template #filters="{ search, forceSubmit }">
                    <nl-search-filters
                        input-key="urlSearch"
                        name="site"
                        :placeholder="$t('searchform.enterdomain')"
                        url-search
                        @search="search"
                        :force-submit="forceSubmit"
                        :is-favorite="true"
                    />
                </template>
                <template #errors="{ props: { loading } }">
                    <div v-if="loading" class="box p-5" style="position: relative">
                        <b-loading :active="loading" :is-full-page="false" />
                    </div>
                    <b-notification active :closable="false" type="is-warning is-light">
                        Vous n'avez pas encore enregistré de favoris.
                    </b-notification>
                </template>
            </SearchLayout>
        </div>
    </div>
</template>

<script>
    import NlSearchFilters from '@/components/Search/NlSearchFilters'
    import { mapActions, mapGetters, mapState } from 'vuex'
    import LoginAs from '@/components/Admin/LoginAs.vue'
    import SearchLayout from '@/components/Search/SearchLayout.vue'

    export default {
        name: 'Favorites',
        title: 'favorites.title',
        props: {
            isAccompagnement: { required: false, type: Boolean, default: false },
            campaign: { required: false, type: Object }
        },
        components: {
            SearchLayout,
            LoginAs,
            NlSearchFilters
        },
        data() {
            return {
                favoritesLoading: false,
                locale: this?.campaign?.project?.lang
            }
        },
        mounted() {
            this.getInfos()
            this.getUserInfos()
        },
        computed: {
            ...mapState({
                project: function (state) {
                    return this.campaign ? this.campaign?.project : state.project
                }
            }),
            ...mapGetters('auth', { user: 'user' }),
            ...mapActions('auth', { credit: 'credit', security: 'security' }),
            ...mapState('search', {
                filters: 'filters',
                meta: 'meta'
            })
        },
        methods: {
            ...mapActions('config', { getModelInfos: 'getModalInfo' }),
            ...mapActions('auth', { getUserInfos: 'me' }),
            ...mapActions('auth', { getInfos: 'getInfos' }),
            ...mapActions('favorites', { getFavorites: 'index' }),
            ...mapActions('search', { searchByFavorites: 'searchByFavorites' }),
            ...mapActions('search', ['resetSearch']),

            generateFavorites() {
                this.favoritesLoading = true
                this.axios
                    .post(`/favorite/generate/${this.project.id}`, {
                        adult: this.filters.adult
                    })
                    .then(response => {
                        console.log(response.data, response.data.total)
                        this.snackNotif(
                            `Les favoris ont été généré avec succès (${response.data.total} ${
                                response.data.total > 1 ? 'favoris ajoutés' : 'favori ajouté'
                            } ${
                                response.data.corrupt > 0
                                    ? `dont ${response.data.corrupt} ${
                                          response.data.corrupt > 1 ? 'corrompus' : 'corrompu'
                                      }`
                                    : ``
                            })`,
                            'is-success',
                            20000
                        )
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(`Une erreur est survenue, impossible de générer les favoris`, 'is-danger')
                    })
                    .finally(() => {
                        this.favoritesLoading = false
                        this.searchByFavorites()
                    })
            },

            removeGeneratedFavorites() {
                this.favoritesLoading = true
                this.axios
                    .delete(`/favorite/removeGenerated/${this.project.id}`)
                    .then(response => {
                        this.snackNotif(
                            `Les favoris générés automatiquement ont bien été supprimé (${response.data.count} favori${
                                response.data.count > 1 ? 's' : ''
                            } supprimé${response.data.count > 1 ? 's' : ''})`,
                            'is-success'
                        )
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(
                            `Une erreur est survenue, impossible de supprimer les favoris générés`,
                            'is-danger'
                        )
                    })
                    .finally(() => {
                        this.favoritesLoading = false
                        this.searchByFavorites()
                    })
            }
        }
    }
</script>
