var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[(
            _vm.campaign &&
            _vm.campaign.project &&
            _vm.campaign.project.user_id &&
            _vm.campaign.project.user_id !== _vm.user.id &&
            _vm.isAccompagnement
        )?_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" Merci de vous connecter au client pour afficher les favoris "),_c('LoginAs',{staticClass:"ml-1 btn btn-link p-0",attrs:{"user-id":this.campaign.customer.id,"manager-id":this.campaign.id,"redirect-to":'/admin/campaigns/details?id=' + this.campaign.id}})],1):_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[(_vm.isAccompagnement)?[_c('div',{staticClass:"d-flex align-items-center w-100",staticStyle:{"gap":"1rem"}},[_c('button',{staticClass:"btn btn-warning text-dark",attrs:{"disabled":_vm.favoritesLoading},on:{"click":_vm.generateFavorites}},[(_vm.favoritesLoading)?_c('i',{staticClass:"fas fa-cog fa-spin"}):_vm._e(),_vm._v(" Lancer les favoris auto ")]),_c('button',{staticClass:"btn btn-warning text-dark",attrs:{"disabled":_vm.favoritesLoading},on:{"click":_vm.removeGeneratedFavorites}},[(_vm.favoritesLoading)?_c('i',{staticClass:"fas fa-cog fa-spin"}):_vm._e(),_vm._v(" Supprimer les favoris auto ")])]),_c('hr')]:_vm._e(),_c('SearchLayout',{attrs:{"get-data":_vm.searchByFavorites,"is-favorite":true},scopedSlots:_vm._u([{key:"filters",fn:function({ search, forceSubmit }){return [_c('nl-search-filters',{attrs:{"input-key":"urlSearch","name":"site","placeholder":_vm.$t('searchform.enterdomain'),"url-search":"","force-submit":forceSubmit,"is-favorite":true},on:{"search":search}})]}},{key:"errors",fn:function({ props: { loading } }){return [(loading)?_c('div',{staticClass:"box p-5",staticStyle:{"position":"relative"}},[_c('b-loading',{attrs:{"active":loading,"is-full-page":false}})],1):_vm._e(),_c('b-notification',{attrs:{"active":"","closable":false,"type":"is-warning is-light"}},[_vm._v(" Vous n'avez pas encore enregistré de favoris. ")])]}}])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }